/**
 * @ComponentFor PageNotFoundViewModel
 */
import React from 'react';
import { translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import BackgroundImage from './BGPattern.svg';
import * as style from 'Shared/Style';

export default (props: {}) => (
  <Main>
    <BackgroundImage style={{ backgroundRepeat: 'repeat' }} />
    <TextSection>
      <h1 style={{ color: style.primary }}>{translate('/Errors/PageNotFoundHeader')}</h1>
      <p style={{ color: style.BLACK, marginLeft: 20 + '%', marginRight: 20 + '%' }}>
        {translate('/Errors/PageNotFoundText')}
      </p>
    </TextSection>
  </Main>
);

const TextSection = styled.div({
  position: 'absolute',
  left: 0 + '%',
  right: 0 + '%',
  top: 35 + '%',
  bottom: 0 + '%',
  textAlign: 'center',
});

const bgImgUrl = BackgroundImage;

const Main = styled.div({
  width: 100 + '%',
  height: 100 + '%',
  minHeight: '350px',
  position: 'relative',
  backgroundImage: 'url(' + bgImgUrl + ')',
});
